<template>
  <div class="template-wrap">
    <a-spin :spinning="spinning">
      <div
        :class="['template', 'template-' + tempId]"
        ref="mapRef"
        @scroll="mapScroll"
      >
        <div style="position: absolute">
          <div class="infos">
            <div class="map-name">{{ data.taskName }}</div>
            <div class="info-box">
              <div class="info-box-left">
                <div class="avatar">
                  <img :src="matter.avatar" alt="avatar" />
                </div>
                <!-- <div class="label">
              学分:
              <i>0</i>
            </div>
            <div class="label">
              积分:
              <i>0</i>
            </div> -->
              </div>
              <div class="info-box-right">
                <div class="row">
                  <span class="label">
                    {{ $t('map.current_level') }}：
                    <!-- 当前关卡： -->
                    <i>{{ data.nowStudyStage }}</i> 
                  </span>
                  <span class="label">
                    {{ $t('map.level_progress') }}：
                    <!-- 关卡进度： -->
                    <i>{{ data.stages[data.nowStudyStage - 1].progress }}%</i>
                  </span>
                </div>
                <div class="row">
                  <span class="label" style="width: 100%">
                    {{ $t('XB_StartDate') }}：
                    <!-- 开始日期： -->
                    <i>{{ dateFormat(data.startTime) }}</i>
                  </span>
                </div>
                <div class="row">
                  <span class="label" style="width: 100%">
                    {{ $t('XB_EndDate') }}：
                    <!-- 截止日期： -->
                    <i>{{ dateFormat(data.endTime) }}</i>
                  </span>
                </div>
                <div class="row">
                  <span class="label">
                    {{ $t('map.overall_progress') }}：
                    <!-- 整体进度： -->
                    <i>{{ data.progress }}%</i>
                  </span>
                  <div class="progress">
                    <div
                      class="bar"
                      :style="{ width: data.progress + '%' }"
                    ></div>
                    <div class="ing" :style="{ left: data.progress + '%' }">
                      <img :src="matter.progressIng" alt="ing" />
                    </div>
                    <div class="end">
                      <img :src="matter.progressEnd" alt="end" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-box-pass" v-if="data.qualifier == 1">
                <img :src="matter.pass" alt="pass" />
              </div>
              <div class="info-box-pass" v-else-if="data.isTimeout">
                <img :src="matter.past" alt="past" />
              </div>
            </div>
            <div class="menus">
              <div class="item" @click="infoVisible = true">
                <img :src="matter.infoBtn" alt="infoBtn" />
              </div>
              <div class="item" @click="rankVisible = true">
                <img :src="matter.rankBtn" alt="rankBtn" />
              </div>
              <div class="item" @click="surveyVisible = true">
                <img :src="matter.menuBtn" alt="menuBtn" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="map"
          :style="{
            backgroundImage: 'url(' + matter.bg + ')',
            width: mapWidth + 'px',
          }"
        >
          <div class="points">
            <div class="ctrl" v-show="showArraw && allPage > 1">
              <LeftOutlined
                class="arrow left"
                @click="pageChange(-1)"
                v-show="currPage != 1"
              />
              <RightOutlined
                class="arrow right"
                @click="pageChange(1)"
                v-show="currPage != allPage"
              />
            </div>
            <div
              class="page"
              v-for="(page, pageIndex) in points"
              :key="pageIndex"
            >
              <template v-if="tempId == 1">
                <template v-if="pageIndex % 2 == 0">
                  <div class="pinwheel-img">
                    <div class="pinwheel1">
                      <img :src="matter.pinwheel1" alt="pinwheel" />
                    </div>
                    <div class="pinwheel2">
                      <img :src="matter.pinwheel2" alt="pinwheel" />
                    </div>
                  </div>
                  <div class="pinwheel-img pinwheel-img2">
                    <div class="pinwheel1">
                      <img :src="matter.pinwheel1" alt="pinwheel" />
                    </div>
                    <div class="pinwheel2">
                      <img :src="matter.pinwheel2" alt="pinwheel" />
                    </div>
                  </div>
                  <div class="balloon-img">
                    <img :src="matter.balloon" alt="balloon" />
                  </div>
                  <div class="cloud1-img1">
                    <img :src="matter.cloud1" alt="cloud" />
                  </div>
                  <div class="cloud2-img1">
                    <img :src="matter.cloud2" alt="cloud" />
                  </div>
                </template>
                <template v-else>
                  <div class="pinwheel-img pinwheel-img3">
                    <div class="pinwheel1">
                      <img :src="matter.pinwheel1" alt="pinwheel" />
                    </div>
                    <div class="pinwheel2">
                      <img :src="matter.pinwheel2" alt="pinwheel" />
                    </div>
                  </div>
                  <div class="balloon-img balloon-img2">
                    <img :src="matter.balloon" alt="balloon" />
                  </div>
                  <div class="cloud1-img2">
                    <img :src="matter.cloud1" alt="cloud" />
                  </div>
                </template>
              </template>
              <template v-else-if="tempId == 2">
                <template v-if="pageIndex % 2 == 0">
                  <div class="star1-img">
                    <img :src="matter.star1" alt="star" />
                  </div>
                  <div class="balloon1-img">
                    <img :src="matter.balloon1" alt="balloon" />
                  </div>
                </template>
                <template v-else>
                  <div class="star2-img">
                    <img :src="matter.star2" alt="star" />
                  </div>
                  <div class="balloon2-img">
                    <img :src="matter.balloon2" alt="balloon" />
                  </div>
                </template>
              </template>
              <template v-else-if="tempId == 3">
                <template v-if="pageIndex % 2 == 0">
                  <div class="walk-img">
                    <img :src="matter.walk" alt="walk" />
                  </div>
                </template>
                <template v-else>
                  <div class="fly-img">
                    <img :src="matter.fly" alt="fly" />
                  </div>
                </template>
              </template>
              <div
                class="position"
                :style="{
                  left: item.position.left,
                  bottom: item.position.bottom,
                  zIndex: 10 - index,
                }"
                v-for="(item, index) in page"
                :key="item.stageId"
              >
                <div class="point-start" v-if="item.stageId == -1">
                  <div class="img">
                    <img :src="matter.start" alt="start" />
                  </div>
                </div>
                <div class="point-end" v-else-if="item.stageId == -2">
                  <div class="str-img" v-if="data.complete == 1">
                    <img :src="matter.successStr" alt="success" />
                  </div>
                  <div class="img">
                    <img :src="matter.end" alt="end" />
                  </div>
                </div>
                <div
                  class="point-step"
                  :class="{
                    active: currStage.stageId == item.stageId,
                    gray: !item.learnFlag,
                  }"
                  @click="stageChange(item, index, pageIndex)"
                  v-else
                >
                  <template v-if="tempId == 1">
                    <div class="success-img">
                      <img
                        :src="matter.success"
                        alt="success"
                        v-if="item.complete == 1"
                      />
                    </div>
                    <div class="main-img">
                      <img
                        :src="matter.step1"
                        alt="step"
                        v-if="item.learnFlag"
                      />
                      <img :src="matter.step2" alt="step" v-else />
                      <span>{{ pageIndex * 5 + index }}</span>
                    </div>
                    <div class="avatar-box-img">
                      <img :src="matter.avatarBox" alt="avatarBox" />
                      <div class="avatar-img">
                        <img :src="matter.avatar" alt="avatar" />
                      </div>
                    </div>
                    <div
                      class="avatar-mini-box-img"
                      v-if="learning[item.stageId]"
                    >
                      <img :src="matter.avatarBox2" alt="avatarBox" />
                      <div class="avatar-img">
                        <img
                          :src="learning[item.stageId].portrait"
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div class="study-people" v-if="learning[item.stageId]">
                      <span>
                        <i>{{ learning[item.stageId].realName }}</i>
                        {{ $t('map.nth_people_study', [learning[item.stageId].users]) }}
                        <!-- 等 人也在学 -->
                      </span>
                    </div>
                    <div class="stage-name">
                      <span>{{ item.stageName }}</span>
                    </div>
                  </template>
                  <template v-else-if="tempId == 2">
                    <div class="success-img">
                      <img
                        :src="matter.success"
                        alt="success"
                        v-if="item.complete == 1"
                      />
                    </div>
                    <div class="main-img">
                      <img
                        :src="matter.step1"
                        alt="step"
                        v-if="item.learnFlag"
                      />
                      <img :src="matter.step2" alt="step" v-else />
                      <span>{{ pageIndex * 5 + index }}</span>
                    </div>
                    <div class="avatar-box-img">
                      <img :src="matter.avatarBox" alt="avatarBox" />
                      <div class="avatar-img">
                        <img :src="matter.avatar" alt="avatar" />
                      </div>
                    </div>
                    <div
                      class="avatar-mini-box-img"
                      v-if="learning[item.stageId]"
                    >
                      <img :src="matter.avatarBox2" alt="avatarBox" />
                      <div class="avatar-img">
                        <img
                          :src="learning[item.stageId].portrait"
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div class="study-people" v-if="learning[item.stageId]">
                      <span>
                        <i>{{ learning[item.stageId].realName }}</i>
                        {{ $t('map.nth_people_study', [learning[item.stageId].users]) }}
                        <!-- 等 人也在学 -->
                      </span>
                    </div>
                    <div class="stage-name">
                      <span>{{ item.stageName }}</span>
                    </div>
                  </template>
                  <template v-else-if="tempId == 3">
                    <div class="success-img">
                      <img
                        :src="matter.success"
                        alt="success"
                        v-if="item.complete == 1"
                      />
                    </div>
                    <div class="active-img">
                      <img :src="matter.active" alt="active" />
                    </div>
                    <div class="main-img">
                      <img
                        :src="matter.step1"
                        alt="step"
                        v-if="item.learnFlag"
                      />
                      <img :src="matter.step2" alt="step" v-else />
                    </div>
                    <div class="avatar-box-img">
                      <img :src="matter.avatarBox" alt="avatarBox" />
                      <div class="avatar-img">
                        <img :src="matter.avatar" alt="avatar" />
                      </div>
                    </div>
                    <div
                      class="avatar-mini-box-img"
                      v-if="learning[item.stageId]"
                    >
                      <img :src="matter.avatarBox" alt="avatarBox" />
                      <div class="avatar-img">
                        <img
                          :src="learning[item.stageId].portrait"
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <div class="study-people" v-if="learning[item.stageId]">
                      <span>
                        <i>{{ learning[item.stageId].realName }}</i>
                        {{ $t('map.nth_people_study', [learning[item.stageId].users]) }}
                        <!-- 等 人也在学 -->
                      </span>
                    </div>
                    <div class="stage-name">
                      <span>{{ item.stageName }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="survey-wrap">
        <surveyDialog
          :isVisible="surveyVisible"
          :tempId="tempId"
          :data="data"
          :collapseKey="data.nowStudyStage"
          @cancal="surveyVisible = false"
        />
      </div>
    </a-spin>
  </div>
  <infoDialog
    :isVisible="infoVisible"
    :tempId="tempId"
    :data="data"
    @cancal="infoVisible = false"
  />
  <stageDialog
    :isVisible="stageVisible"
    :spinning="spinning"
    :tempId="tempId"
    :mapData="data"
    :data="currStage"
    @study="toStudy"
    @cancal="stageCancal"
  />
  <rankDialog
    :isVisible="rankVisible"
    :tempId="tempId"
    :taskId="data.taskId"
    :userId="userInfo.userId"
    @cancal="rankVisible = false"
  />
  <studyDialog
    :isVisible="studyVisible"
    :stage="currStage"
    :detail="currStudy"
    @cancal="studyCancal"
  />
  <teachDialog
    :isVisible="teachVisible"
    :stage="currStage"
    :detail="currStudy"
    @cancal="studyCancal"
  />
  <homework ref="homeworkRef" @updata:homework="studyCancal" />
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  onBeforeUnmount,
} from "vue";
import ls from "@/utils/local-storage";
import teachDialog from "./teachDialog.vue";
import infoDialog from "./infoDialog.vue";
import stageDialog from "./stageDialog.vue";
import rankDialog from "./rankDialog.vue";
import surveyDialog from "./surveyDialog.vue";
import studyDialog from "./studyDialog.vue";
import homework from "@/components/homework/index.vue";
import { mapLearning, mapUnlock, mapStageDetail } from "@/api/map";
import { dateFormat } from "@/utils/tools";

export default defineComponent({
  components: {
    teachDialog,
    infoDialog,
    stageDialog,
    rankDialog,
    surveyDialog,
    studyDialog,
    homework,
  },
  props: {
    tempId: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default: () => null,
    },
    pointData: {
      type: Array,
      default: [],
    },
    points: {
      type: Array,
      default: [],
    },
  },
  emits: ["mapReload"],
  setup(props, { emit }) {
    const userInfo = ls.get("userInfo");
    // 素材
    let matter = {};
    if (props.tempId == 1) {
      matter = {
        avatar: userInfo.portrait,
        avatarBox: require(`./static/temp_1/tx-bg.png`),
        avatarBox2: require(`./static/temp_3/tx-bg.png`),
        bg: require(`./static/temp_1/bg.jpg`),
        start: require(`./static/temp_1/start.png`),
        end: require(`./static/temp_1/end.png`),
        step1: require(`./static/temp_1/step-1.png`),
        step2: require(`./static/temp_1/step-2.png`),
        pass: require(`./static/temp_1/pass.png`),
        past: require(`./static/temp_1/past.png`),
        success: require(`./static/temp_1/success.png`),
        successStr: require(`./static/temp_1/success-txt.gif`),
        progressIng: require(`./static/temp_1/progress-ing.png`),
        progressEnd: require(`./static/temp_1/progress-end.png`),
        infoBtn: require(`./static/temp_1/path-info.png`),
        rankBtn: require(`./static/temp_1/path-ph.png`),
        menuBtn: require(`./static/temp_1/path-gq.png`),
        balloon: require(`./static/temp_1/balloon.png`),
        pinwheel1: require(`./static/temp_1/fc-1.png`),
        pinwheel2: require(`./static/temp_1/fc-2.png`),
        cloud1: require(`./static/temp_1/cloud1.png`),
        cloud2: require(`./static/temp_1/cloud2.png`),
      };
    } else if (props.tempId == 2) {
      matter = {
        avatar: userInfo.portrait,
        avatarBox: require(`./static/temp_2/tx-bg.png`),
        avatarBox2: require(`./static/temp_3/tx-bg.png`),
        bg: require(`./static/temp_2/bg.jpg`),
        start: require(`./static/temp_2/start.png`),
        end: require(`./static/temp_2/end.png`),
        step1: require(`./static/temp_2/step-1.png`),
        step2: require(`./static/temp_2/step-2.png`),
        pass: require(`./static/temp_2/pass.png`),
        past: require(`./static/temp_1/past.png`),
        success: require(`./static/temp_2/success.png`),
        successStr: require(`./static/temp_2/success-txt.gif`),
        progressIng: require(`./static/temp_2/progress-ing.png`),
        progressEnd: require(`./static/temp_2/progress-end.png`),
        infoBtn: require(`./static/temp_2/path-info.png`),
        rankBtn: require(`./static/temp_2/path-ph.png`),
        menuBtn: require(`./static/temp_2/path-gq.png`),
        balloon1: require(`./static/temp_2/balloon.png`),
        balloon2: require(`./static/temp_2/balloon2.png`),
        star1: require(`./static/temp_2/star-1.png`),
        star2: require(`./static/temp_2/star-2.png`),
      };
    } else if (props.tempId == 3) {
      matter = {
        avatar: userInfo.portrait,
        avatarBox: require(`./static/temp_3/tx-bg.png`),
        bg: require(`./static/temp_3/bg.jpg`),
        start: require(`./static/temp_3/start.png`),
        end: require(`./static/temp_3/end.png`),
        step1: require(`./static/temp_3/step-1.png`),
        step2: require(`./static/temp_3/step-2.png`),
        pass: require(`./static/temp_3/pass.png`),
        past: require(`./static/temp_1/past.png`),
        success: require(`./static/temp_3/success.png`),
        successStr: require(`./static/temp_3/success-txt.gif`),
        active: require(`./static/temp_3/active.gif`),
        walk: require(`./static/temp_3/walk.gif`),
        fly: require(`./static/temp_3/fly.gif`),
        progressIng: require(`./static/temp_3/progress-ing.png`),
        progressEnd: require(`./static/temp_3/progress-end.png`),
        infoBtn: require(`./static/temp_3/path-info.png`),
        rankBtn: require(`./static/temp_3/path-ph.png`),
        menuBtn: require(`./static/temp_3/path-gq.png`),
      };
    }
    // 地图尺寸
    const mapSize = 1366;
    // 判断数字所在数组区间
    const inScopes = (arr, num) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] > num) {
          return i - 1;
        }
      }
    };

    const state = reactive({
      spinning: false,
      mapWidth: mapSize * (props.pointData.length / 5),
      allPage: Math.ceil(props.pointData.length / 5),
      currPage: 1,
      showArraw: true,
      currStage: props.pointData[props.data.nowStudyStage],
      currStudy: {},
      infoVisible: false,
      stageVisible: false,
      rankVisible: false,
      surveyVisible: false,
      studyVisible: false,
      teachVisible: false,
      learning: {},
    });

    const mapRef = ref(null);

    // mapPage scrollLeft范围
    let scopes = [];
    for (let i = 0; i < state.allPage; i++) {
      scopes.push(mapSize * i);
    }
    scopes.push(state.mapWidth);

    // 滚动
    const mapScroll = (e) => {
      if (state.showArraw) {
        let left = e.srcElement.scrollLeft;
        state.currPage = inScopes(scopes, left) + 1;
      }
    };

    // 箭头切换
    let scrollTimer = null;
    const pageChange = (val) => {
      state.showArraw = false;
      state.currPage += val;
      let left = mapSize * state.currPage - mapSize;
      scrollTimer = setInterval(() => {
        if (
          Math.abs(mapRef.value.scrollLeft - left) < 5 ||
          (Math.abs(
            mapRef.value.scrollLeft -
              ((state.mapWidth % mapSize) + (state.allPage - 2) * mapSize)
          ) < 5 &&
            state.currPage == state.allPage &&
            props.pointData.length % 5 != 0)
        ) {
          clearInterval(scrollTimer);
          setTimeout(() => {
            state.showArraw = true;
          }, 10);
        } else {
          mapRef.value.scrollLeft += val * 30;
          if (Math.abs(mapRef.value.scrollLeft - left) < 30) {
            mapRef.value.scrollLeft = left;
          }
        }
      }, 10);
    };

    // 同时学习人员
    mapLearning(props.data.taskId).then((res) => {
      let d = res.data || [];
      for (let i = 0; i < d.length; i++) {
        state.learning[d[i].stageId] = d[i];
      }
    });

    // 关卡切换
    const stageChange = (stage, index, pageIndex) => {
      if (stage.learnFlag && stage.startUnlock == 0) {
        state.spinning = true;
        mapUnlock(props.data.taskId, {
          stageId: stage.stageId,
        }).then((res) => {
          if (res.ret == 0) {
            mapStageDetail(props.data.taskId, stage.stageId).then((res2) => {
              if (res.ret == 0) {
                stage = res2.data;
                stage.learnFlag = true;
                if (stage.learnOrder == 1) {
                  stage.details[0].learnFlag = true;
                } else {
                  for (let i = 0; i < stage.details.length; i++) {
                    stage.details[i].learnFlag = true;
                  }
                }
                state.currStage = stage;
                state.stageVisible = true;
              }
            });
          }
          state.spinning = false;
        });
      } else {
        state.currStage = stage;
        state.stageVisible = true;
      }
      props.data.nowStudyStage = pageIndex * 5 + index;
    };

    // 关卡窗口关闭
    const stageCancal = () => {
      state.stageVisible = false;
      emit("mapReload", props.data.nowStudyStage);
    };

    // 学习
    const homeworkRef = ref(null);
    const toStudy = (data) => {
      state.currStudy = data;
      if (data.resourceType == 5) {
        homeworkRef.value.openModal({
          id: props.data.taskId,
          did: data.detailId,
        });
      } else if (data.resourceType == 43) {
        state.teachVisible = true;
      } else {
        state.studyVisible = true;
      }
    };

    // 学习窗口关闭
    const studyCancal = () => {
      state.studyVisible = false;
      state.teachVisible = false;
      state.spinning = true;
      mapStageDetail(props.data.taskId, state.currStage.stageId).then((res) => {
        if (res.ret == 0) {
          state.spinning = false;
          let stage = res.data;
          stage.learnFlag = state.currStage.learnFlag;
          for (let j = 0; j < stage.details.length; j++) {
            let detail = stage.details[j];

            if (stage.learnOrder == 2) {
              if (stage.learnFlag || detail.complete == 1) {
                detail.learnFlag = true;
              }
            } else {
              // ==1 资源按顺序学习
              if (j == 0) {
                if (stage.learnFlag || detail.complete == 1) {
                  detail.learnFlag = true;
                }
              } else {
                if (
                  ( stage.learnFlag&&
                    stage.details[j - 1].complete == 1) ||
                  ( detail.complete == 1)
                ) {
                  detail.learnFlag = true;
                }
              }
            }
          }
          state.currStage = stage;
        }
      });
    };

    onMounted(() => {
      // 自动滚动到当前关卡
      let s = Math.ceil((props.data.nowStudyStage + 1) / 5) - 1;
      let n = 0;
      let t = setInterval(() => {
        if (n == s) {
          clearInterval(t);
        } else {
          if (state.showArraw) {
            pageChange(1);
            n++;
          }
        }
      }, 10);
    });

    onBeforeUnmount(() => {
      if (scrollTimer) clearInterval(scrollTimer);
    });

    return {
      dateFormat,
      matter,
      userInfo,
      ...toRefs(state),
      mapRef,
      mapScroll,
      pageChange,
      stageChange,
      stageCancal,
      toStudy,
      homeworkRef,
      studyCancal,
    };
  },
});
</script>
<style scoped lang="less">
@import "./static/temp_1/style.less";
@import "./static/temp_2/style.less";
@import "./static/temp_3/style.less";
.template-wrap {
  width: 1366px;
  height: 768px;
  overflow: hidden;
  position: relative;
  .survey-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.template {
  width: 1366px;
  height: 768px;
  overflow-x: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
